<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @addTap="addTap" />
    <listTable
      :list="list"
      :loading="loading"
      :pageShow="false"
      :columns="columns" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import ListToolbar from '@/components/system/listToolbar.vue'
import { schoolKindDel, schoolKindSubmit, schoolKinList } from '@/apis/common'
import listTable from '@/components/system/listTable.vue'
import Form from '@/components/system/form.vue'

export default {
  name: 'detail',
  components: { Form, listTable, ListToolbar },
  data() {
    return {
      loading: false,
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      toolbar: [
        {type: 'button', label: '添加', def: 'primary', key: 'add'},
      ],
      list: [],
      columns: [
        {label: '明细ID', prop: 'id', width: 60, type: 'text'},
        {label: '明细名称', prop: 'name', width: 150, type: 'text'},
        {label: '创建时间', prop: 'created_at', width: 150, type: 'text'},
        {label: '操作', width: 100, type: 'but', arr: [
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      formDrawerVisible: false,
      typeForm: 'add',
      addColumns: [
        {label: '明细名称', prop: 'name', type: 'input',value: ''},
      ],
      addFormData: {}
    }
  },
  mounted() {
    this.getSchoolKinList()
  },
  methods: {
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible = true
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        kind_id: n === 'edit' ? this.addFormData.id : '',
        ...e
      }
      schoolKindSubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getSchoolKinList()
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        schoolKindDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          kind_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getSchoolKinList()
        })
      }).catch(() => {})
    },
    getSchoolKinList() {
      this.loading = true
      schoolKinList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
      }).then(res => {
        this.list = res.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
